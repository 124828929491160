import qs from 'qs'
import { useAuthStore } from '@/store/auth'
import type { ActivitiesFilters, Activity, PaginationResponse, Pagination } from '@/types'
import { useUiStore } from '~/store/ui'
import { POPUPS } from '~/components/dynamic/maps'
import { useLibrariesStore } from '~/store/libraries'
import { CALENDAR_ACTIVITY_STATUSES, CALENDAR_ACTIVITY_TYPES } from '~/constants'

export const useGetCalendarActivities = async (params: Object): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(params, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch(`/calendars/activities?${queryFormatted}`, { key: queryFormatted })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarActivities(params)
  }

  if (error.value) throw createError(error.value?.message)

  return data.value as PaginationResponse
}

export const useGetCalendarTimelineActivities = async (params: Object): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(params, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch(`/calendars/timeline/activities?${queryFormatted}`, { key: queryFormatted })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarTimelineActivities(params)
  }

  if (error.value) throw createError(error.value?.message)

  return data.value as PaginationResponse
}

export const useCreateCalendarActivity = async (activity: Activity): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities`, { method: 'POST', body: activity })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useCreateCalendarActivity(activity)
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useGetCalendarActivity = async (activityId: number): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}`)

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarActivity(activityId)
  }
  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useUpdateCalendarActivity = async (activity: Partial<Activity>): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activity.id}`, { method: 'PATCH', body: activity })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useUpdateCalendarActivity(activity)
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useUpdateCalendarActivityStatus = async (activityId: number, statusId: number): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}/set-status`, {
    method: 'POST',
    body: { status_id: statusId },
  })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useUpdateCalendarActivityStatus(activityId, statusId)
  } else if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useDeleteCalendarActivity = async (activityId: number): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}`, {
    method: 'DELETE',
  })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useDeleteCalendarActivity(activityId)
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useGetCalendarStats = async (): Promise<any> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/statistics`)

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGetCalendarStats()
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as any
}

export const useGoogleSync = () => {
  const user = useUser()
  const route = useRoute()
  const config = useRuntimeConfig()

  const redirectTo = useCookie('redirectTo')
  redirectTo.value = route.href

  return navigateTo(
    `${config.public.API_URL}google/accounts/${user?.id}/oauth?redirect=${config.public.APP_URL}google/calendars/error-callback`,
    {
      external: true,
    }
  )
}

export const useGoogleSyncCallback = async (googleId: string): Promise<any> => {
  const { data, error } = await useAPIFetch('/google/accounts/sync', { method: 'PATCH', body: { google_id: googleId } })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useGoogleSyncCallback(googleId)
  }

  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data
}

export const useRefreshAccessToken = async () => {
  const authStore = useAuthStore()
  const { data, error } = await useAPIFetch(`/google/accounts/${authStore.getUser?.id}/oauth/refresh`, {
    method: 'post',
  })
  if (error.value) throw createError(error.value)

  return data.value as any
}
export const useGetCallLogs = async (params: Object): Promise<PaginationResponse> => {
  const queryFormatted = qs.stringify(params, { arrayFormat: 'comma', encode: false })
  const { data, error } = await useAPIFetch(`/calendars/activities/call-log?${queryFormatted}`, { key: queryFormatted })

  if (error.value) throw createError(error.value)

  return data.value as PaginationResponse
}

export const useSetMeetingOutcome = async (
  activityId: number,
  resolutionId: number,
  note?: string
): Promise<Activity> => {
  const { data, error } = await useAPIFetch(`/calendars/activities/${activityId}/meeting-outcome`, {
    method: 'POST',
    body: { meeting_outcome_resolution_id: resolutionId, ...(note ? { meeting_outcome_note: note } : {}) },
  })

  if (error.value?.statusCode === 401) {
    await useRefreshAccessToken()
    return useSetMeetingOutcome(activityId, resolutionId, note)
  }
  if (error.value) throw createError(error.value?.message)

  return (data.value as any).data as Activity
}

export const useGetOverdueTasks = async () => {
  const uiStore = useUiStore()
  const librariesStore = useLibrariesStore()
  const settingsMapping = {
    ACTIVITY_ONCE_WEEK_SHOW_POPUP_TO_CLOSE_ACTIVITY_TO_DONE_CHECKBOX:
      'activity_once_week_show_popup_to_close_activity_to_done_checkbox',
    ACTIVITY_ONCE_WEEK_SHOW_POPUP_TO_CLOSE_ACTIVITY_TO_DONE_DAY_OF_WEEK:
      'activity_once_week_show_popup_to_close_activity_to_done_day_of_week',
  }
  const settingCodes = Object.values(settingsMapping)

  try {
    const [
      {
        settings: { activity_once_week_show_popup_to_close_activity_to_done_checkbox: checkWeeklySetting },
      },
      {
        settings: { activity_once_week_show_popup_to_close_activity_to_done_day_of_week: dayOfWeekSetting },
      },
    ] = await Promise.all(settingCodes.map((code) => useGetSettingsByCode(code)))

    // check if setting is enabled and if the day of the week is today
    if (!+checkWeeklySetting || Number(dayOfWeekSetting) !== new Date().getDay()) return

    await Promise.all([await useCalendarActivityStatuses(), await useCalendarActivityTypes()])

    // Get all the pending tasks for this user
    const query: ActivitiesFilters & Pagination = {
      type_ids: librariesStore.getCalendarActivityTypes
        ?.filter((type) => type.code === CALENDAR_ACTIVITY_TYPES.TASK)
        .map((type) => type.id),
      status_ids: librariesStore.getCalendarActivityStatuses
        .filter((status) => status.code === CALENDAR_ACTIVITY_STATUSES.OVERDUE)
        .map((status) => status.id),
      user_id: useAuthStore().getUser?.id!,
      limit: 1000,
    }
    const { data } = await useGetCalendarActivities(query)
    if (!data.length) return

    uiStore.showPopup(POPUPS.OVERDUE_TASKS, { tasks: data })
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

export const setBulkActivityStatus = async (activityIds: number[], statusId: number) =>
  await useAPIFetch(`/calendars/activities/set-status/bulk`, {
    method: 'POST',
    body: { activity_ids: activityIds, status_id: statusId },
  })
