<template>
  <Teleport to="body">
    <transition name="fade">
      <div
        v-if="session"
        class="fixed inset-x-0 bottom-12 z-20 mx-auto flex h-auto w-full flex-row items-center justify-between rounded-2xl bg-black-90 p-4 text-white shadow sm:w-8/12 lg:w-[666px]"
      >
        <audio ref="remoteAudio"></audio>
        <div class="flex flex-col">
          <span v-if="lead.name" class="text-subhead-1">{{ lead.name }}</span>
          <span v-if="useGetShowPhoneLead(lead)" class="text-body-2">00{{ currentNumber }}</span>
        </div>
        <div class="flex flex-row items-center gap-10">
          <div class="flex flex-row items-center gap-4">
            <span class="text-body-2">{{ parsedTime }}</span>
            <button
              class="flex size-10 items-center justify-center rounded-full bg-error-100 active:bg-error-80"
              @click="terminate"
            >
              <UiIcon name="call-off" class="text-white"></UiIcon>
            </button>
          </div>
          <div class="flex flex-row items-center gap-4">
            <button
              class="flex size-10 items-center justify-center rounded-full bg-white/20 active:bg-white/40"
              :class="{ 'bg-white/40': sessionIsMuted }"
              @click="toggleMute"
            >
              <UiIcon name="micro" class="text-white"></UiIcon>
            </button>
            <button
              class="relative flex size-10 items-center justify-center rounded-full bg-white/20 active:bg-white/40"
            >
              <transition name="fade">
                <LeadCallAudioDevices v-model="showDevices" />
              </transition>
              <UiIcon name="settings" class="text-white" @click="showDevices = !showDevices"></UiIcon>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import type { CallOptions } from 'jssip/lib/UA'
import type { EndEvent } from 'jssip/lib/RTCSession'
import { IncomingResponse } from 'jssip/lib/SIPMessage'
import { useIOStore } from '~/store/io'
import { useUiStore } from '~/store/ui'
import { useAuthStore } from '@/store/auth'
import { useLibrariesStore } from '@/store/libraries'
import type { Lead } from '@/types'
import { STATUSES } from '~/constants'

const ioStore = useIOStore()
const uiStore = useUiStore()
const authStore = useAuthStore()
const librariesStore = useLibrariesStore()

type Props = {
  lead: Lead
  localNumber?: string
  testMode?: boolean
  fullPhone?: string
  callWay?: string
  supportPanel?: boolean
}

const emits = defineEmits<{
  (e: 'terminated', refresh: boolean): void
  (e: 'called', duration: number): void
  (e: 'no-answer'): void
  (e: 'get-call-id', callId: string): void
}>()

const props = withDefaults(defineProps<Props>(), {
  callWay: 'crm',
  supportPanel: false,
})

const remoteAudio = ref()
const duration = ref(0)
let timer: NodeJS.Timer
const sessionIsMuted = ref(false)
const showDevices = ref(false)
const session = ref()
let phone = null
const currentUserStatus = useCookie('userStatus')
const currentNumber = ref(props.fullPhone || `${props.lead.phone_country.phone_code}${props.lead.phone}`)

const configEnv = useRuntimeConfig()
// Slice method delete '/' in the end
const domain = configEnv.public.APP_URL.split('.')[1]?.slice(0, -1) || 'app'

const isIpPhone = computed(() => props.callWay === 'phone')

const callStatusData = [
  {
    codes: [486, 600],
    message: 'The lead is busy',
    sound: `https://cdn.thrivestate.${domain}/sounds/busy.mp3`,
  },
  {
    codes: [403, 603],
    message: 'The call was rejected',
    sound: `https://cdn.thrivestate.${domain}/sounds/rejected.mp3`,
  },
  {
    codes: [300, 301, 302, 305, 380],
    message: 'The call was redirected',
    sound: `https://cdn.thrivestate.${domain}/sounds/redirected.mp3`,
  },
  {
    codes: [480, 410, 408, 430, 487],
    message: 'The lead is unavailable',
    sound: `https://cdn.thrivestate.${domain}/sounds/unavailable.mp3`,
  },
  {
    codes: [404, 604],
    message: 'The call was not found',
    sound: `https://cdn.thrivestate.${domain}/sounds/notFound.mp3`,
  },
  {
    codes: [484],
    message: 'The call address is incomplete',
    sound: `https://cdn.thrivestate.${domain}/sounds/incompatible.mp3`,
  },
  {
    codes: [488, 606],
    message: 'The call SDP is incompatible',
    sound: `https://cdn.thrivestate.${domain}/sounds/incomplete.mp3`,
  },
  {
    codes: [401, 407],
    message: 'Authentication error',
    sound: `https://cdn.thrivestate.${domain}/sounds/authenticationError.mp3`,
  },
  {
    codes: [],
    message: 'Call error',
    sound: `https://cdn.thrivestate.${domain}/sounds/error.mp3`,
  },
]

const { smaller } = useBreakpoints(useGetBreakpoints())
const isMobile = smaller('xs')

const handleCallEnd = () => {
  const isCallSuccessful = duration.value > 0

  if (isCallSuccessful) {
    emits('called', duration.value)
  }

  // Update lead history only in case that connection was terminated before call or ended by error
  emits('terminated', !isCallSuccessful)
}

const isIncomingResponse = (object: any): object is IncomingResponse => {
  return object && object.status_code !== undefined
}

const failed = async (e: EndEvent, code?: number) => {
  const [, , , CALL_UNAVAILABLE] = callStatusData
  const CALL_ERROR = callStatusData.at(-1)!

  await getBackStatus()

  let errorMessage = CALL_ERROR

  if (isIpPhone.value || isIncomingResponse(e.message)) {
    const errorCode = isIpPhone.value ? code : e.message.status_code

    if (!props.testMode) {
      if (CALL_UNAVAILABLE.codes.includes(errorCode)) {
        return emits('no-answer')
      }
    }

    errorMessage = callStatusData.find((error) => error.codes.includes(errorCode)) || CALL_ERROR!
  }

  emits('terminated', true)

  nextTick(() => {
    uiStore.showSnackBanner(`Error : ${errorMessage.message}`, 'error')
    if (!isMobile.value) new Audio(errorMessage.sound).play()
  })
}

const ended = async () => {
  await getBackStatus()

  handleCallEnd()
}

const accepted = () => {
  timer = setInterval(() => {
    duration.value += 1
  }, 1000)
}

const config: CallOptions = {
  eventHandlers: {
    failed,
    ended,
    accepted,
  },
  mediaConstraints: { audio: true },
  pcConfig: {
    iceServers: [
      {
        urls: ['stun:global.stun.twilio.com:3478'],
      },
    ],
  },
  extraHeaders: [],
}

const getHeaderCall = async () => {
  const payload = {
    lead_id: props.lead.id,
    call_way: props.callWay,
    did: props.localNumber || null,
    lead_phone: '+' + currentNumber.value,
  }
  try {
    const headers = await useVoiceCallStarted(payload)

    emits('get-call-id', headers['X-CRM-Call-ID'] as string)

    if (isIpPhone.value) {
      subscribeToIpCallChannel(authStore.getUser?.id as number)
      return
    }

    const extraProperties = [
      `X-CRM-Lead-ID: ${props.lead.id}`,
      `X-CRM-Call-ID: ${headers['X-CRM-Call-ID']}`,
      `X-CRM-Environment: ${headers['X-CRM-Environment']}`,
    ]
    config.extraHeaders = extraProperties
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const formattedPhone = computed(() => {
  return props.supportPanel ? currentNumber.value : `00${currentNumber.value}`
})

onNuxtReady(async () => {
  phone = await ioStore.getUserAgent()
  // status 1 - ready
  if (phone!.status !== 1) {
    phone!.start()
    phone!.on('connected', () => {
      startCall()
    })
    // wait 5 seconds. If connection is still not established, show error page
    setTimeout(() => {
      if (phone!.status !== 1) {
        throw showError({
          statusCode: 503,
        })
      }
    }, 5000)
  } else {
    startCall()
  }
})

const startCall = async () => {
  try {
    if (!props.testMode) {
      await getHeaderCall()
    }

    await setStatusDontDisturb()

    if (isIpPhone.value) return

    //  add twillio credentials
    const twilioCredentials = await useGetCallsTwillioCredentials()
    config.pcConfig!.iceServers![1] = twilioCredentials

    if (props.localNumber) {
      config.extraHeaders!.push(`X-CRM-Preffered-DID: ${props.localNumber}`)
    }

    session.value = phone!.call(`tel:${formattedPhone.value}`, config)

    // Check the current device
    const isSafari = !!window.GestureEvent

    if (isSafari) {
      // This is for Safari.
      session.value.connection.addEventListener('track', (e: any) => {
        remoteAudio.value.srcObject = e.streams[0]
        remoteAudio.value.play()
      })
    } else {
      // This is for Chrome,Firefox, etc.
      session.value.connection.addEventListener('addstream', (e: any) => {
        remoteAudio.value.srcObject = e.stream
        remoteAudio.value.play()
      })
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const setStatusDontDisturb = async () => {
  const dontDisturbStatus = librariesStore.getStatuses.find((s) => s.code === STATUSES.DONT_DISTURB)?.id
  if (authStore.getUser.status === dontDisturbStatus) return

  currentUserStatus.value = authStore.getUser.status?.toString()
  await setStatus(dontDisturbStatus)
}

const getBackStatus = async () => {
  if (currentUserStatus.value) {
    await setStatus(Number(currentUserStatus.value))
    currentUserStatus.value = null
  }
}

const setStatus = async (statusId?: number) => {
  authStore.setUserStatus(statusId!)
  await useSetUserStatus(Number(authStore.getUser.id), statusId!)
}

const parsedTime = computed(() => {
  if (duration.value === 0) return 'Calling...'
  const second = duration.value % 60
  const minute = Math.floor(duration.value / 60) % 60
  return `${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
})

const toggleMute = () => {
  if (session.value?.isMuted()) {
    session.value?.unmute()
  } else {
    session.value?.mute()
  }
  sessionIsMuted.value = !sessionIsMuted.value
}

const terminate = () => {
  session.value?.terminate()

  handleCallEnd()
}

const showIpPhoneCallStatus = async (e: any) => {
  // eslint-disable-next-line camelcase
  const { duration: resDuration, outcome_code } = e
  unSubscribeToIpCallChannel(authStore.getUser?.id as number)
  const code = Number(outcome_code)
  if (code === 200) {
    duration.value = resDuration
    await ended()
  } else {
    await failed(e, code)
  }
}

onUnmounted(() => {
  clearInterval(timer)
})

watch(
  () => ioStore.getHasActivatedPhoneCall,
  (value) => {
    if (isIpPhone.value && value) {
      showIpPhoneCallStatus(value.data)
    }
  }
)
</script>

<style scoped></style>
